import { Navbar } from '../../components/composite'
import screenshot1 from '../../assets/images/screenshot1.png'
import { Button } from '../../components/core'

export default function Intro() {
  return (
    <div className="bg-intro-desktop bg-cover flex flex-col items-center">
      <Navbar />
      <div className="absolute left-0 top-0 bottom-0 right-0 bg-bezier1 bg-no-repeat bg-right-bottom" />
      <div className="flex w-full justify-end">
        <div className="flex pt-16 pb-40 relative">
          <div className="flex-1 pl-32 pr-28 z-2">
            <p className="text-green-100 text-xs py-10 font-primary">
              * COMING SOON
            </p>
            <h1 className="text-white-100 text-4xl font-primary font-medium">
              An AI-enabled Blockchain Trust Platform
            </h1>
            <p className="text-grey-100 text-sm py-5 font-primary">
              Safeguarding the Blockchain Ecosystem with Fraud Detection and
              Market Manipulation Insights.
            </p>
            <Button title="Start Now" size="sm" classname="rounded-md" />
          </div>
          <div>
            <img src={screenshot1} alt="Screenshot 1" />
          </div>
        </div>
      </div>
    </div>
  )
}
