import screenshot from '../../assets/images/screenshot6.png'
import { Button } from '../../components/core'

export default function Influencer() {
  return (
    <div className="flex flex-row-reverse justify-between pr-32 py-20 relative items-center">
      <div className="pl-28 z-2 max-w-[600px]">
        <p className="text-green-100 text-xs py-2 font-primary">
          SOCIAL MEDIA AND CHANNEL ANALYTICS
        </p>
        <h1 className="text-white-100 text-4xl font-primary font-medium">
          Seamlessly monitor social media presence and follow the right
          influencers
        </h1>
        <p className="text-grey-100 text-sm py-5 font-primary">
          Find and Follow influencers who resonate with your interests and
          values, empowering you to make informed choices and connect with those
          who genuinely inspire you.
        </p>
        <Button
          title="View Kratos"
          size="sm"
          variant="outline"
          borderColor="green"
          classname="text-gray-50 rounded-md"
        />
      </div>
      <div className="px-32 bg-bezier5 bg-no-repeat bg-right-bottom">
        <img src={screenshot} alt="Screenshot" />
      </div>
    </div>
  )
}
