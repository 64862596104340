import screenshot4 from '../../assets/images/screenshot4.png'
import { Button } from '../../components/core'

export default function Assistance() {
  return (
    <div className="flex flex-row-reverse justify-between px-32 py-20 relative items-center">
      <div className="pl-28 z-2 max-w-[600px]">
        <p className="text-green-100 text-xs py-2 font-primary">
          CRYTO AI-ASSISTANT
        </p>
        <h1 className="text-white-100 text-4xl font-primary font-medium">
          Analytics for Everyone in User-friendly view. The Beauty is in
          Simplicity
        </h1>
        <p className="text-grey-100 text-sm py-5 font-primary">
          Experience the power of simplified insights at your fingertips. Our
          integrated AI tools leverage extensive blockchain ecosystem data to
          provide personalized and instant answers that cater to your unique
          needs.
        </p>
        <Button
          title="View Medusa"
          size="sm"
          variant="outline"
          borderColor="green"
          classname="text-gray-50 rounded-md"
        />
      </div>
      <div className="bg-bezier3 bg-no-repeat bg-right-bottom">
        <img src={screenshot4} alt="Screenshot 4" />
      </div>
    </div>
  )
}
