import { HEADER } from '../../../constants/resource'
import { Button } from '../../core'
import logo from '../../../assets/images/logo-nav.png'

const Navbar = () => (
  <div className="max-w-7xl">
    <nav className="container flex justify-between items-center z-20">
      <div className="my-5 lg:my-6">
        <img src={logo} alt="bitmetrix logo" />
      </div>

      <div className="hidden lg:block text-sm text-white-100 px-20">
        {HEADER.navItems.map((navItem) => (
          <a
            key={`nav-${navItem.id}`}
            className="mx-3 py-5 hover:gradient-border-bottom font-primary text-gray-400"
            href={navItem.link}
          >
            {navItem.label}
          </a>
        ))}
      </div>

      <div className="flex">
        <Button
          title="Sign In"
          variant="transparent"
          classname="text-gray-50"
        />
        <Button title="Sign Up" variant="light" classname="rounded-md" />
      </div>
    </nav>
  </div>
)

export default Navbar
