import React, { SyntheticEvent } from 'react'

interface IButton {
  variant?: string
  title: string
  size?: string
  type?: string
  color?: 'white' | 'black' | 'green' | 'gray'
  borderColor?: 'gray' | 'green' | undefined
  onClick?: () => void
  classname?: string
}

const Button: React.FC<IButton> = ({
  title,
  variant = 'primary',
  size = 'sm',
  color = 'white',
  type = 'md',
  borderColor,
  // eslint-disable-next-line prettier/prettier
  onClick = () => { },
  classname,
}) => {
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClick()
  }

  let bgStyle = 'bg-green-100 hover:bg-green-600'
  let borderStyle = ''
  if (variant === 'light') {
    bgStyle = 'bg-white-100 hover:bg-white-600'
  } else if (variant === 'transparent') {
    bgStyle = ''
    borderStyle =
      borderColor !== undefined
        ? borderColor === 'green'
          ? 'border-green-100 border'
          : 'border-gray-600 border'
        : ''
  } else if (variant === 'outline') {
    bgStyle = ''
    borderStyle =
      borderColor === 'green'
        ? 'border-green-100 border'
        : 'border-gray-600 border'
  }
  console.log('borderstyle', title, borderStyle)

  const className = `${classname} min-w-[100px] text-center font-semibold px-2 py-2 font-medium text-[14px] font-primary shadow-${size} ${bgStyle} ${borderStyle}`
  return (
    <button className={className} onClick={handleClick}>
      {title}
    </button>
  )
}

export default Button
