import screenshot from '../../assets/images/screenshot5.png'
import { Button } from '../../components/core'

export default function Benchmark() {
  return (
    <div className="flex justify-between pl-32 py-10 relative items-center">
      <div className="pr-28 z-2 max-w-[600px]">
        <p className="text-green-100 text-xs py-2 font-primary">
          LIVE BUSINESS AND ECOSYSTEM DATA
        </p>
        <h1 className="text-white-100 text-4xl font-primary font-medium">
          Deep dive into unparalleled blockchain benchmarking metrics
        </h1>
        <p className="text-grey-100 text-sm py-5 font-primary">
          Discover valuable insights across diverse blockchains and tokenized
          entities with understanding of user demographics, project dynamics,
          growth rates, and more.
        </p>
        <Button
          title="View Atlas"
          size="sm"
          variant="outline"
          borderColor="green"
          classname="text-gray-50 rounded-md"
        />
      </div>
      <div className="bg-bezier4 bg-no-repeat bg-right-bottom py-32">
        <img src={screenshot} alt="Screenshot" />
      </div>
    </div>
  )
}
