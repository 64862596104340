import React from 'react'
import './App.css'
import './styles/tailwind.css'
import LandingPage from './pages/Landing'

function App() {
  return <LandingPage />
}

export default App
