import logoAws from '../../assets/images/logo-aws.png'
import logoFaculty from '../../assets/images/logo-faculty.png'
import logoRedBeard from '../../assets/images/logo-redbeard.png'
import logoCoppola from '../../assets/images/logo-coppola.png'

export default function Partner() {
  return (
    <div className="flex flex-col items-center py-20">
      <h1 className="text-white-100 text-4xl font-primary font-medium">
        Our Partners
      </h1>
      <div className="flex py-16">
        <img src={logoAws} alt="AWS logo" />
        <img src={logoFaculty} alt="Faculty logo" />
        <img src={logoRedBeard} alt="Red Beard logo" />
        <img src={logoCoppola} alt="Coppola logo" />
      </div>
    </div>
  )
}
