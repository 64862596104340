import Intro from './intro'
import Partner from './partner'
import Ecosystem from './ecosystem'
import Safety from './safety'
import Assistance from './assitance'
import Benchmark from './benchmark'
import Influencer from './influencer'
import Solution from './solution'
import FAQ from './faq'
import Demo from './demo'
import Footer from './footer'

export default function Landing() {
  return (
    <div>
      <Intro />
      <Partner />
      <Ecosystem />
      <Safety />
      <Assistance />
      <Benchmark />
      <Influencer />
      <Solution />
      <FAQ />
      <Demo />
      <Footer />
    </div>
  )
}
