import screenshot from '../../assets/images/screenshot10.png'
import { Button } from '../../components/core'

export default function FAQ() {
  return (
    <div className="flex flex-row justify-between py-20 relative items-center">
      <div className="pl-28 z-2 max-w-[700px]">
        <h1 className="text-white-100 text-4xl font-primary font-medium">
          Frequently Asked Questions
        </h1>
        <p className="text-grey-100 text-sm py-5 font-primary">
          Lorem ipsum dolor sit amet consectetur. In porttitor odio etiam
          integer quam maecenas sed amet. Viverra eu sagittis et ipsum.
        </p>
        <Button title="View FAQ" size="sm" classname="rounded-md" />
      </div>
      <div className="px-32 bg-no-repeat bg-right-bottom w-1/2">
        <img src={screenshot} alt="Screenshot" />
      </div>
    </div>
  )
}
