import screenshot2 from '../../assets/images/screenshot2.png'
import icon from '../../assets/images/Icon.png'
import { Button } from '../../components/core'

export default function Solution() {
  return (
    <div className="bg-solution-desktop">
      <div className="py-20 text-white-100">
        <div className="max-w-[1024px] pl-32">
          <h1 className="text-4xl font-primary font-medium">Our Solutions</h1>
          <div className="flex py-6 justify-between">
            <Button
              title="Bank"
              variant="primary"
              classname="rounded-full text-black"
            />
            <Button
              title="Government"
              variant="transparent"
              borderColor="gray"
              classname="rounded-full text-gray-400"
            />
            <Button
              title="Tokenised Entities"
              variant="transparent"
              borderColor="gray"
              classname="rounded-full text-gray-400"
            />
            <Button
              title="Trading Funds"
              variant="transparent"
              borderColor="gray"
              classname="rounded-full text-gray-400"
            />
            <Button
              title="Marketing Agencies"
              variant="transparent"
              borderColor="gray"
              classname="rounded-full text-gray-400"
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="bg-background-100 flex max-w-screen-lg items-center justify-center rounded-md border border-slate-600 border-solid pl-12">
            <div className="flex-1">
              <p className="text-2xl pr-10 font-primary">Banks</p>
              <p className="text-grey-100 font-primary">
                Onboard AML compliant Clients
              </p>
              <p className="text-xl pt-4 pr-10 font-primary">
                <img src={icon} alt="Icon" className="inline mr-2"></img>
                <span>AML and Terrorist Financing Alerts</span>
              </p>
              <p className="text-grey-100 font-primary">
                Get alerts on high-risk activities such as cryptocurrency
                transactions involving addresses identified based on specified
                AML compliance policies, and investigate accordingly
              </p>
            </div>
            <div className="bg-ecosystem-box bg-no-repeat bg-right-top py-12">
              <img src={screenshot2} alt="Screenshot 2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
