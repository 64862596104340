import screenshot2 from '../../assets/images/screenshot2.png'
import { Button } from '../../components/core'

export default function Ecosystem() {
  return (
    <div className="bg-background-200 relative">
      <div className="absolute left-0 top-0 bottom-0 right-0 bg-ecosystem-desktop bg-no-repeat bg-left-bottom" />
      <div className="py-20 flex flex-col items-center text-white-100 items-center">
        <div className="max-w-[700px] text-center">
          <h1 className="text-4xl font-primary font-medium">
            Be part of a transparent and trusted blockchain ecosystem today
          </h1>
          <p className="py-10 text-grey-100 font-primary">
            Leveraging advanced Artificial Intelligence to conduct comprehensive
            cryptocurrency due diligence, analyze wallet behaviour, market
            trends, and identify price manipulation and fraudulent activities
            proactively
          </p>
        </div>

        <div className="bg-background-100 flex max-w-screen-lg items-center justify-center rounded-md border border-slate-600 border-solid pl-12 z-10">
          <div className="flex-1">
            <p className="text-2xl pr-10 font-primary">
              Built to solve complex crypto problems
            </p>
            <p className="pt-10 text-grey-100 font-primary">
              Providing users a single platform/ source of truth into crypto
              market trends and projects, to make confident choices
            </p>
            <div className="flex pt-10">
              <Button
                title="Get started"
                variant="primary"
                classname="text-black rounded-md"
              />
              <Button
                title="Schedule a Demo"
                variant="transparent"
                color="green"
              />
            </div>
          </div>
          <div className="bg-ecosystem-box bg-no-repeat bg-right-top py-12 ml-20">
            <img src={screenshot2} alt="Screenshot 2" />
          </div>
        </div>
      </div>
    </div>
  )
}
