import React, { SyntheticEvent } from 'react'

interface IInput {
  placeholder: string
  onChange?: () => void
}

// eslint-disable-next-line prettier/prettier
const Input: React.FC<IInput> = ({ placeholder, onChange = () => { } }) => {
  const handleChange = (e: SyntheticEvent) => {
    e.stopPropagation()
    onChange()
  }

  return (
    <div
      className="relative mb-3 border-b-gray-600 hover:border-b-green-100 border-b"
      data-te-input-wrapper-init
    >
      <input
        type="text"
        className="font-primary text-neutral-200 peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-white peer-hover:text-white data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-white dark:peer-focus:text-white [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
        id="exampleFormControlInput1"
        placeholder={placeholder}
        onChange={handleChange}
      />
      <label
        htmlFor="exampleFormControlInput1"
        className="font-primary text-white text-sm pointer-events-none absolute top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.7] peer-focus:text-neutral-200 peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-neutral-200"
      >
        {placeholder}
      </label>
    </div>
  )
}

export default Input
