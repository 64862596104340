export const HEADER = {
  navItems: [
    {
      id: 'products',
      label: 'Products',
      link: '#products',
      children: [],
    },
    {
      id: 'solutions',
      label: 'Solutions',
      link: '#solutions',
      children: [],
    },
    {
      id: 'company',
      label: 'Company',
      link: '#company',
      children: [],
    },
    {
      id: 'resources',
      label: 'Resources',
      link: '#resources',
      children: [
        {
          id: 'resources-child0',
          label: 'Assets',
          link: '#assets',
        },
        {
          id: 'resources-child1',
          label: 'Images',
          link: '#images',
        },
      ],
    },
  ],
}
