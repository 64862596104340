import logo from '../../assets/images/logo-nav.png'

export default function Footer() {
  return (
    <div className="pt-16">
      <div className="flex flex-row justify-between relative">
        <div className="pl-28 z-2 max-w-[540px]">
          <div>
            <img src={logo} alt="bitmetrix logo" />
          </div>
          <p className="text-grey-100 text-sm py-5 font-primary">
            Revolutionise the way you research, trade, and grow your crypto
            portfolio with Bitmetrix
          </p>
        </div>
        <div className="flex pr-28">
          <div className="pl-10 mr-16">
            <h4 className="text-white-100 text-xl py-2 font-primary">
              Products
            </h4>
            <p className="text-grey-100 text-sm py-2 font-primary">
              Cerberus: Fraud Intelligence
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Medusa: User Profiling
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Atlas: Market Movement
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Kratos: Token Analytics
            </p>
          </div>
          <div className="pl-10 mr-16">
            <h4 className="text-white-100 text-xl py-2 cursor-pointer font-primary">
              Solutions
            </h4>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Banks
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Government
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Tokenised Entities
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Trading Funds
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Marketing Agencies
            </p>
          </div>
          <div className="pl-10 mr-36">
            <h4 className="text-white-100 text-xl py-2 font-primary">
              Resource Hub
            </h4>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              About Bitmetrix
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Blog
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              FAQ
            </p>
            <p className="text-grey-100 text-sm py-2 cursor-pointer font-primary">
              Contact Us
            </p>
          </div>
        </div>
      </div>
      <div className="flex py-3 flex border-t-gray-600 border-t ml-28 mr-56 my-20">
        <p className="text-grey-100 text-sm py-1 cursor-pointer font-primary grow">
          © Copyright Bitmetrix 2023. All Rights Reserved.
        </p>
        <p className="text-grey-100 text-sm py-1 cursor-pointer font-primary pr-20">
          Disclaimer
        </p>
        <p className="text-grey-100 text-sm py-1 cursor-pointer font-primary">
          Privacy Policy
        </p>
      </div>
    </div>
  )
}
