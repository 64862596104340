import screenshot3 from '../../assets/images/screenshot3.png'
import { Button } from '../../components/core'

export default function Safety() {
  return (
    <div className="flex justify-between px-32 pt-40 pb-40 relative">
      <div className="pr-28 z-2 max-w-[600px]">
        <p className="text-green-100 text-xs py-2 font-primary">
          FRAUD INTELLIGENCE
        </p>
        <h1 className="text-white-100 text-4xl font-primary font-medium">
          Detect Team Selling Activities: Your Ultimate Crypto Safety Net.
        </h1>
        <p className="text-grey-100 text-sm py-5 font-primary">
          Stay ahead of fraudsters with our state-of-the-art Artificial
          Intelligence engine. Receive real-time notifications on founding teams
          engaging in selling activities, and uncover token price manipulation
          as it occurs across any Blockchain ecosystem.
        </p>
        <Button
          title="View Cerberus"
          size="sm"
          variant="outline"
          borderColor="green"
          classname="text-gray-50 rounded-md"
        />
      </div>
      <div className="bg-bezier2 bg-no-repeat bg-right-top">
        <img src={screenshot3} alt="Screenshot 3" />
      </div>
    </div>
  )
}
