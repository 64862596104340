import { Button, Input } from '../../components/core'

export default function Demo() {
  return (
    <div className="bg-demo-desktop">
      <div className="flex flex-row justify-between py-20 relative items-center">
        <div className="pl-28 z-2 max-w-[700px]">
          <h1 className="text-white-100 text-4xl font-primary font-medium">
            Schedule a Demo
          </h1>
          <p className="text-grey-100 text-sm py-5 font-primary">
            Unlock the next level of crypto analytics with Bitmetrix!
          </p>
          <div className="grid gap-2 grid-cols-2 grid-rows-5">
            <Input placeholder="First name" />
            <Input placeholder="Last name" />
            <Input placeholder="Email" />
            <Input placeholder="Company name" />
            <Input placeholder="Role" />
            <Input placeholder="Industry" />
            <div className="col-span-2">
              <Input placeholder="What can we help you with?" />
            </div>
            <div className="col-span-2">
              <Input placeholder="Message" />
            </div>
          </div>
          <Button title="Submit" size="sm" classname="mt-8 rounded-md" />
        </div>
      </div>
    </div>
  )
}
